<template>
  <div class="content-wrapper">
    <bo-page-title :no-add="true" />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <div class="col-md-auto">
                    <div class="form-group mb-0">
                        <button @click="apiGet" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right.noninteractive ><i class="icon-spinner11"></i></button>
                    </div>
                </div>    
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status_terlaksana" :options="mrStatus" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <div class="col-md-7">
                    <div class="form-group mb-0">
                            <div class="input-group">
                                <date-range-picker
                                    ref="picker"
                                    :locale-data="datePickerConfig.locale"
                                    :autoApply="datePickerConfig.autoApply"
                                    v-model="dateRange"
                                    :opens="'right'"
                                    :ranges="datePickerConfig.ranges"
                                    @update="updateValues"
                                >
                                    <template v-slot:input="picker">
                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                    </template>
                                </date-range-picker>
                                <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                </div>
                            </div>
                    </div>
                </div>
              </b-form-row>
            </b-col>
            <div class="col-lg-4">
                <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                    <input class="form-control"
                        v-model="filter.search"
                        @input="doSearch"
                        placeholder="Ketik Nama/No. RM Pasien"
                    />
                    <div class="form-control-feedback">
                        <i class="icon-search4 text-indigo"></i>
                    </div>
                        
                    <b-button
                        class="ml-1 d-inline-flex align-items-center"
                        variant="outline-success"
                        id="resetBtn"
                        @click="doResetData()"
                    >Reset</b-button>
                </div>
            </div>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp"></div>
            </template>

            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            
            <template #cell(waktu_operasi)="v">
              <span class="font-weight-semibold">{{v.item.arano_tanggal_operasi_start | moment('DD MMMM YYYY')}}</span><br><span>{{v.item.arano_jam_operasi_start}}</span>
            </template>

            <template #cell(nama)="v">
              <div>{{v.item.ap_fullname}} <br> {{v.item.ap_nik}}</div>
              <small class="text-secondary">Terdaftar {{v.item.ap_created_date | moment('DD/MM/yyyy HH:mm')}}</small>
            </template>

            <template #cell(jenis_tindakan)="v">
              <p>{{ v.value }}</p>
              <span>{{ v.item.data_jenis_tindakan }}</span>
            </template>

            <template #cell(ruangan)="v">
              <div class="font-weight-semibold">{{v.item.kamar}}</div>
              <small><span>{{v.item.bangsal}}</span> - <span>{{v.item.kelas}}</span></small>
            </template>

            <template #cell(status)="v">
              <div class="badge badge-success mb-1" v-if="v.value == 1">
                Terlaksana
              </div>
              <div class="badge badge-warning mb-1" v-else>
                Belum Terlaksana
              </div>
            </template>           
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Gen from '@/libs/Gen.js'

const _ = global._
const moment = require('moment')

export default {
  extends: GlobalVue,
  components:{DateRangePicker},
  data() {
    return {
        idKey:'aranjo_id',
        statusKey: 'aranjo_terlaksana',
        datePickerConfig: {
            startDate: new Date(),
            endDate: new Date(),
            autoApply: true,
            ranges: {
                'Hari Ini': [new Date(), new Date()],
                '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
            },
            applyClass: 'btn-sm btn-primary',
            cancelClass: 'btn-sm btn-light',
            locale: {
                applyLabel: 'Terapkan',
                cancelLabel: 'Batal',
                direction: 'ltr',
                format: 'mm/dd/yyyy',
                separator: ' - ',
            }
        },
        dateRange: {
            startDate: new Date(moment().subtract(6, 'days')),
            endDate: new Date(),
        },
        mrStatus: [
            {text : "Terlaksana", value :"Y"},
            {text : "Belum Terlaksana", value :"N"},
        ],
        fields: [
            {
                key: 'number',
                label: '#',
            },
            {
                key: 'waktu_operasi',
                label: 'Waktu Operasi',
            },
            {
                key: 'nama',
                label: 'Nama / NO KTP',
            },
            {
                key: 'ruangan',
                label: 'Ruangan',
            },
            {
                key: 'jenis_tindakan',
                label: 'Jenis Tindakan',
            },
            {
                key: 'kamar_operasi',
                label: 'Kamar Operasi',
            },
            {
                key: 'dokter_operator',
                label: 'Dokter Operator',
            },
            {
                key: 'dokter_anestesi',
                label: 'Dokter Anestesi',
            },
            {
                key: 'status',
                label: 'Status',
            },
        ],
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.doConvertDate()          
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
    filters: {
        date(val) {
            return val ? moment(val).format("D MMM YYYY") : ""
        }
    },
  methods: {
    doFill(){
      this.doFilter()
    },
    doConvertDate(){
      if(this.dateRange.startDate){
        this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      }
      if(this.dateRange.endDate){
        this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      }
    },
    doResetData(){
        this.doReset()
        this.dateRange =  {
            startDate: null,
            endDate: null,
        }
        this.doConvertDate()
    },
    updateValues(e){
        this.doConvertDate()
        this.doFill()
    },
    doSearch: _.debounce(function(){
      this.doFilter()
    },1000),
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>